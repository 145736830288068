import React from 'react';
import Link from 'gatsby-link';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NavLink = props => {
	if (!props.test) {
		return <Link to={props.url}>{props.text}</Link>;
	}
	return null;
};

export default function LocationListPage({ pageContext }) {
	const { group, index, first, last, pageCount } = pageContext;
	const previousUrl = index - 1 == 1 ? '/' : (index - 1).toString();
	const nextUrl = (index + 1).toString();

	return (
		<Layout>
			<SEO title={`Flowerwall Locations | Page ${pageCount}`} description="See if we can service your event!" />
			<div className="container m-auto">
				<h1>Servicing the Greater Milwaukee Area</h1>
				<h3>Locations</h3>
				<section className="flex flex-wrap">
					{group.map(({ node }) => (
						<div className="w-full md:w-1/2" key={node.id}>
							<Link to={node.frontmatter.path}>{node.frontmatter.title}</Link>
							<div>{node.frontmatter.date}</div>
						</div>
					))}
				</section>
				<div className="previousLink">
					<NavLink test={first} url={previousUrl} text="Go to Previous Page" />
				</div>
				<div className="nextLink">
					<NavLink test={last} url={nextUrl} text="Go to Next Page" />
				</div>
			</div>
		</Layout>
	);
}
